<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <v-card outlined class="p-2">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="6" md="3">
                                <h6 class="blue-lcd mb-1">Customer</h6>
                                <v-autocomplete
                                    prepend-inner-icon="mdi-account-box"
                                    clearable
                                    solo
                                    v-model="customer"
                                    :items="customers"
                                    item-value="cust_id"
                                    item-text="cust_name"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="7">
                                <div class="d-flex mt-6 p-2" style="border: thin solid rgba(0, 0, 0, 0.12); border-radius: 12px;">
                                    <v-checkbox
                                        v-model="filter"
                                        label="Type"
                                        value="type"
                                        class="pt-0 pb-0 mt-2 mr-5"
                                        hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="filter"
                                        label="Thick"
                                        value="thick"
                                        class="pt-0 pb-0 mt-2 mr-5"
                                        hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="filter"
                                        label="Width"
                                        value="width"
                                        class="pt-0 pb-0 mt-2 mr-5"
                                        hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="filter"
                                        label="AZ"
                                        value="coat_mass"
                                        class="pt-0 pb-0 mt-2 mr-5"
                                        hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="filter"
                                        label="Grade"
                                        value="grade"
                                        class="pt-0 pb-0 mt-2 mr-5"
                                        hide-details
                                    ></v-checkbox>
                                    <!-- <v-checkbox
                                        v-model="filter"
                                        label="Color"
                                        value="color"
                                        class="pt-0 pb-0 mt-2 mr-2"
                                        hide-details
                                    ></v-checkbox> -->
                                    <v-checkbox
                                        v-model="filter"
                                        label="Sisa"
                                        value="sisa"
                                        class="pt-0 pb-0 mt-2 mr-2"
                                        hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="filter"
                                        label="Cons"
                                        value="cons"
                                        class="pt-0 pb-0 mt-2 mr-2"
                                        hide-details
                                    ></v-checkbox>
                                    <!-- <v-checkbox
                                        v-model="filter"
                                        label="Date Release"
                                        value="dt_release"
                                        class="pt-0 pb-0 mt-2 mr-2"
                                        @change="getRelease()"
                                        hide-details
                                    ></v-checkbox> -->
                                </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2" v-if="show_dt_release">
                                <h6 class="blue-lcd mb-1">Date From</h6>
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2" v-if="show_dt_release" >
                                <h6 class="blue-lcd mb-1">Date To</h6>
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-btn class="mt-7 border-12" 
                                color="info" elevation="2" large @click="submit(1,10)">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-2">
            <v-col cols="12">
                <v-card class="elevation-1 rounded-l" outlined>
                    <v-card-text >
                        <v-data-table
                            fixed-header
                            height="450"
                            dense
                            :headers="headers"
                            :items="sales_contracts"
                            :loading="loading"
                            loading-text="Please wait, retrieving data"
                            :items-per-page="20"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                            }"
                            :search="searchItem"
                            page-count="10"
                            class="elevation-1"
                        >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-100">
                                        Result
                                        <v-spacer></v-spacer>
                                        <v-text-field solo style="max-width: 300px;" class="border-12" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                            <!--  -->
                            <template v-slot:[`item.wgt_ord`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt_ord) }} Kg
                            </template>
                            <template v-slot:[`item.wgt_shipped`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt_shipped) }} Kg
                            </template>
                            <template v-slot:[`item.wgt_outstanding`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt_outstanding) }} Kg
                            </template>
                            <template v-slot:[`item.thick`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.thick) }} mm
                            </template>
                            <template v-slot:[`item.width`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.width) }} mm
                            </template>
                            <template v-slot:[`item.dt_cust_aprv`]="{ item }">
                                {{ (new Date(new Date(item.dt_cust_aprv) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'Order',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Sales Order Status',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            loading: false,
            saless: [],
            sales: '',
            customers: [],
            customer: '',
            search: null,
            order_id: '',
            loadingOrder: false,
            items:[],
            outstandings: [
                {
                    value: 'Y',
                    label: 'Yes'
                },
                {
                    value: 'N',
                    label: 'No'
                }
            ],
            outstanding: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            headers: [],
            sales_contracts: [],
            options: {},
            totalItems: 10,
            pagination: 1, 
            searchItem: '',
            po_item: {},
            po_items: [],
            dialog: false,
            pagination_item: 1,
            loading3: false,
            totalItem_po_items: 10,
            option_po_items: {},
            header_po_items: [
                { text: 'Item', value: 'item_num' },
                { text: 'Descr', value: 'descr' },
                { text: 'Req Week', value: 'req_ship_week' },
                { text: 'Order (Kg)', value: 'wgt_ord' },
                { text: 'Receive (Kg)', value: 'wgt_rsv' },
                { text: 'PPP (Kg)', value: 'wgt_ppp' },
                { text: 'Delivery (Kg)', value: 'wgt_deliv' },
                { text: 'Ship (Kg)', value: 'wgt_shipped' },
                { text: 'Stock (Kg)', value: 'wgt_stock' },
                { text: 'Plan (Kg)', value: 'wgt_plan' },
                { text: 'Outstanding (Kg)', value: 'wgt_outstanding' }
            ],
            searchDetail: '',
            data_var: {
                entity_id : 'SR',
                appl_id : 'WEBSR'
            },
            group: '',
            filter: [],
            sortBy: '',
            sortName: '',
            show_dt_release: false
        }
    },
    async mounted(){
        await this.getGroupUser()
        await this.getSales()
        await this.getCustomer()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'SALESID') {

                        if (res.data.data[i]['var_value'] != null) {

                            this.salesid = res.data.data[i]['var_value']
                            this.sales = res.data.data[i]['var_value']
                            this.getSales()
                            this.getCustomer()
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'CUSTID') {
                        this.customer = res.data.data[i]['var_value']
                        this.getCustomer()
                    }
                }

                // this.submit(1,10)

            })
        },
        async getGroupUser(){
            await axios.get(`${process.env.VUE_APP_URL}/api/get_group_user?entity_id=SM&appl_id=WEBSM`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.group = res.data.data
                this.getEnvConf()

            });
        },
        close(){
            this.dialog = false
        },
        async getSales(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/sales?salesid=${this.salesid}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.saless = res.data
            });
        },
        async getCustomer(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/CustOrder?groupid=${this.group}&txtSalesman=${this.sales}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.customers = res.data
            });
        },
        async querySelections (value) {
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/sales-contract?search=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.items = res.data
            });
        },
        sortByFunc(sortBy){
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.submit(1, 10)
        },
        getRelease(){
            this.show_dt_release = this.show_dt_release === true ? false : true
        },
        async submit(page = 1, itemsPerPage = 10){
            this.loading = true
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            var type = '';
            var thick = '';
            var width = '';
            var coat_mass = '';
            var grade = '';
            var color = '';
            var sisa = '';
            var cons = '';
            var dt_release = '';

            for (let index = 0; index < this.filter.length; index++) {
                if (this.filter[index] == 'type') {
                    type = 'Y'
                }  
                if (this.filter[index] == 'thick') {
                    thick = 'Y'
                }  
                if (this.filter[index] == 'width') {
                    width = 'Y'
                }  
                if (this.filter[index] == 'coat_mass') {
                    coat_mass = 'Y'
                }  
                if (this.filter[index] == 'grade') {
                    grade = 'Y'
                }  
                if (this.filter[index] == 'color') {
                    color = 'Y'
                } 
                if (this.filter[index] == 'sisa') {
                    sisa = 'Y'
                } 
                if (this.filter[index] == 'cons') {
                    cons = 'Y'
                } 
                if (this.filter[index] == 'dt_release') {
                    dt_release = 'Y'
                }                
            }

            this.headers = [
                { text: 'Customer', value: 'cust_name', align: 'left', sortable: false }
            ]

            this.sales_contracts = []
            var arrayReusable = []

            await axios.get(`${process.env.VUE_APP_URL}/api/sm/order_status?txtCustomer=${this.customer ? this.customer : ''}&txtOutstanding=${this.outstanding ? this.outstanding : ''}&brand=${type ? type : ''}&thick=${thick ? thick : ''}&width=${width ? width : ''}&coat_mass=${coat_mass ? coat_mass : ''}&grade=${grade ? grade : ''}&color=${color ? color : ''}&sisa=${sisa ? sisa : ''}&cons=${cons ? cons : ''}&dt_release=${dt_release ? dt_release : ''}&txtStart=${start_date ? start_date : ""}&txtEnd=${end_date ? end_date : ""}&sort=${this.sortBy ? this.sortBy : 'asc'}&sortName=${this.sortName ? this.sortName : 'cust_id'}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                if (type) {
                    this.headers.push(
                        {
                            text: 'Brand', value: 'brand', align: 'left'
                        }
                    )
                }

                if (thick) {
                    this.headers.push(
                        {
                            text: 'Thick', value: 'thick', align: 'right'
                        }
                    )
                }

                if (width) {
                    this.headers.push(
                        {
                            text: 'Width', value: 'width', align: 'right'
                        }
                    )
                }

                if (coat_mass) {
                    this.headers.push(
                        {
                            text: 'AZ', value: 'coat_mass', align: 'left'
                        }
                    )
                }

                if (grade) {
                    this.headers.push(
                        {
                            text: 'Grade', value: 'grade_id', align: 'left'
                        }
                    )
                }

                if (color) {
                    this.headers.push(
                        {
                            text: 'Colour', value: 'color', align: 'left'
                        }
                    )
                }

                if (sisa) {
                    this.headers.push(
                        {
                            text: 'Sisa', value: 'sisa', align: 'left'
                        }
                    )
                }

                if (cons) {
                    this.headers.push(
                        {
                            text: 'Cons', value: 'cons_name', align: 'left'
                        }
                    )
                }

                if (dt_release) {
                    this.headers.push(
                        {
                            text: 'Date Release', value: 'dt_cust_aprv', align: 'left'
                        }
                    )
                }

                arrayReusable = []

                arrayReusable = [
                    { text: 'Weight Order', value: 'wgt_ord', align: 'right' },
                    { text: 'Weight Ship', value: 'wgt_shipped', align: 'right' },
                    { text: 'Weight Outstandings', value: 'wgt_outstanding', align: 'right' }
                ]

                for (let index = 0; index < arrayReusable.length; index++) {
                    this.headers.push(arrayReusable[index]);
                }

                this.loading = false
                this.sales_contracts = res.data
                
            })
        },
        // showItem(item){
        //     console.log(item);
        //     this.po_item = item
        //     this.po_items = []
        //     this.dialog = true
        //     this.pagination_item = 1
        //     this.getPullDataDetail()
        // },
        // async getPullDataDetail(){
        //     this.loading3 = true
        //     await axios.get(`${process.env.VUE_APP_URL}/api/sm/order_report/show?txtOrderID=${this.po_item.order_id}&item_num=${this.po_item.stat}`, { 
        //         headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        //     })
        //     .then(res => {
        //         this.po_items = res.data
        //         this.loading3 = false
        //     })
        // },
    },
    watch: {
        search (val) {
            val && val !== this.order_id && this.querySelections(val)
        },
    }
}
</script>
